// see date formats here https://day.js.org/docs/en/display/format
// [h] - escaping characters
import {PLACEHOLDER, ROUND_OPTION, NON_BREAK_SPACE} from './const';

export const LANG_SETTING = {
    en: {
        DateText: `Best rate available ${PLACEHOLDER.DateFormat}`,
        DateFormat: `MMMM${NON_BREAK_SPACE}D,${NON_BREAK_SPACE}YYYY`,
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    fr: {
        DateText: `Le meilleur prix pour le ${PLACEHOLDER.DateFormat}`,
        DateFormat: `D${NON_BREAK_SPACE}MMMM,${NON_BREAK_SPACE}YYYY`,
        MinLosText: `Lors de la réservation à partir de ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `pour la promotion "${PLACEHOLDER.Offer}"`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    zh: {
        DateText: `${PLACEHOLDER.DateFormat} 最優價`,
        DateFormat: `D${NON_BREAK_SPACE}MMMM,${NON_BREAK_SPACE}YYYY`,
        MinLosText: `從${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosDay}開始預訂`,
        OfferText: `用於"${PLACEHOLDER.Offer}"促銷活動`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    kk: {
        DateText: `Ең тиімді баға ${PLACEHOLDER.DateFormat}`,
        DateFormat: 'DD.MM.YYYY',
        MinLosText: `${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay} бастап брондау кезінде`,
        OfferText: `"${PLACEHOLDER.Offer}" акциясы үшін`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: false,
        PriceDelimiter: ',', // by default is dot
    },
    ru: {
        DateText: `Самая выгодная цена на ${PLACEHOLDER.DateFormat}`,
        DateFormat: `D${NON_BREAK_SPACE}MMMM${NON_BREAK_SPACE}YYYY`,
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    ar: {
        DateText: ` أفضل سعر لعام${PLACEHOLDER.DateFormat}`,
        DateFormat: 'YYYY.MM.DD',
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        UsePriceRound: ROUND_OPTION.RoundNearest,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
};
